// https://github.schibsted.io/SvD/web-backend/blob/master/assets/javascripts/config/svp-player-config.js

import get from 'lodash.get';
import pick from 'lodash.pick';
import { DESKTOP, TABLET, PHONE, getDeviceType } from '../util';

import getVideoKeywords from './svd/get-video-keywords';

const invCodePrefixMap = new Map([
    [PHONE, 'se-svd-tv-wph'],
    [TABLET, 'se-svd-tv-wta'],
    [DESKTOP, 'se-svd-tv-wde'],
]);

/**
 *
 * @param {String} deviceType desktop|tablet|phone
 * @param {Object} [params={}]
 * @param {Boolean} [params.isAfp=false]
 * @param {Object} [params.types=[]]
 * @returns {Object}
 */
function getVideoPlacements(deviceType, { isAfp = false, types = [] } = {}) {
    const afpPrefix = isAfp ? 'afp_' : '';
    const invCodePrefix = invCodePrefixMap.get(deviceType);

    const adTypes = {
        pause: {
            invCode: `${invCodePrefix}-${afpPrefix}pause`,
            sizes: [[825, 424]],
        },
        preroll: {
            inv_code: `${invCodePrefix}-${afpPrefix}preroll`,
        },
        postroll: {
            inv_code: `${invCodePrefix}-${afpPrefix}postroll`,
        },
    };

    return types.length ? pick(adTypes, types) : adTypes;
}

/**
 * @param {String} [locationSearch = '']
 * @returns {Object}
 */
function vendorConfigSvd({ locationSearch = '' }) {
    const deviceType = getDeviceType();

    return {
        vendor: 'svd',
        locale: 'se',
        sharing: false,
        mute: {
            desktop: true,
            mobile: false,
        },
        autoplay: {
            desktop: true,
            mobile: false,
        },
        settings: {
            pulse: {
                host: 'se-aftonbladet.videoplaza.tv',
            },
        },
        adn: (stream) => {
            const streamMetadata = get(stream, 'attributes.category.attributes.metadata');
            const isAfp = get(streamMetadata, 'afp.value') === 'true';
            const streamCategories = (get(streamMetadata, 'special-categories.value') || '').split(',');
            const keyworsSets = getVideoKeywords({ deviceType, streamCategories, locationSearch });
            const keywordsKwStripped = get(keyworsSets, 'kw-stripped');
            const keywordsKwPrefixed = get(keyworsSets, 'kw-prefixed');

            return Promise.resolve({
                member: 9943,
                adSlots: {
                    schedule: getVideoPlacements(deviceType, {
                        isAfp,
                        types: ['preroll', 'postroll'],
                    }),
                    params: {
                        ...keywordsKwPrefixed,
                        vskippable: '0',
                        vplaybackmethod: '2',
                    },
                },
                adPlacements: {
                    schedule: getVideoPlacements(deviceType, {
                        isAfp,
                        types: ['pause'],
                    }),
                    params: {
                        keywords: keywordsKwStripped,
                    },
                },
            });
        },
        skin: {
            name: 'svd-player',
        },
        recommended: false,
    };
}

export default vendorConfigSvd;
