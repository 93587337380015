import { PHONE } from '../../util';

/**
 * @returns {Object} { {Number} width, {Number} height }
 */
const getViewportInfo = () => {
    const { documentElement } = window.document;

    let width = 0;
    let height = 0;

    if (typeof window.innerWidth !== 'undefined') {
        width = window.innerWidth;
        height = window.innerHeight;
    } else if (typeof documentElement !== 'undefined' && typeof documentElement.clientWidth !== 'undefined') {
        width = documentElement.clientWidth;
        height = documentElement.clientHeight;
    }

    return { width, height };
};

/**
 *
 * @returns {String} ios|android|unknown
 */
function getMobileOS() {
    const containsIPhone = navigator.userAgent.match(/iphone/i);
    const containsAndroid = navigator.userAgent.match(/android/i);
    if (containsIPhone && !containsAndroid) {
        return 'ios';
    }
    if (containsAndroid && !containsIPhone) {
        return 'android';
    }
    return 'unknown';
}

/**
 *
 * @param {String} name
 * @returns {String|null}
 */
function getCookie(name) {
    try {
        const cookieValue = decodeURIComponent(
            document.cookie.replace(
                new RegExp(
                    `(?:(?:^|.*;\\s*)${encodeURIComponent(name).replace(/[\-\.\+\*]/g /* eslint-disable-line no-useless-escape */, '\\$&')}\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*)|.*`,
                ),
                '$1',
            ),
        );
        return cookieValue || null;
    } catch (e) {
        return null;
    }
}

/**
 *
 * @param {String} deviceType
 * @param {Object} params
 * @returns {Object}
 */
export default function getANParams(deviceType, params = {}) {
    const {
        articleId,
        device: { isApp } = {},
        user: { isLoggedIn, hasPlus } = {},
        clientProperties: { appnexusAdParams: { pageGen, sections } = {} } = {},
        requestHeader,
        singlePageEnabled,
        lazySectionsEnabled,
    } = params;

    const viewportInfo = getViewportInfo();
    const abAppInfo = getCookie('abAppInfo');
    const mobileOS = getMobileOS();
    let navMode = 'normal';

    if (lazySectionsEnabled) {
        navMode = 'lazy';
    } else if (singlePageEnabled) {
        navMode = 'spa';
    }

    const SE_GENERIC = 'se-generic';
    const MANDATORY_PREFIX = 'aa-sch';
    const AB_PREFIX = 'se-aftonbladet';

    // Please keep in alphabetical order, Add new params to tests
    return {
        [`${MANDATORY_PREFIX}-country_code`]: 'se', // MANDATORY
        [`${MANDATORY_PREFIX}-inventory_type`]: undefined, // MANDATORY
        [`${MANDATORY_PREFIX}-page_type`]: 'article', // MANDATORY
        [`${MANDATORY_PREFIX}-publisher`]: 'aftonbladet', // MANDATORY
        [`${MANDATORY_PREFIX}-supply_type`]: `web_${deviceType}`, // MANDATORY
        [`${AB_PREFIX}-section`]: sections,
        [`${AB_PREFIX}-navigation`]: navMode,
        [`${SE_GENERIC}-appmode`]: isApp ? 'hybrid' : 'notinapp',
        [`${SE_GENERIC}-appversion`]: abAppInfo ? JSON.parse(abAppInfo).appVersion : undefined,
        [`${SE_GENERIC}-articleid`]: articleId,
        ...(requestHeader && { [`${SE_GENERIC}-test`]: 'autotest' }),
        [`${SE_GENERIC}-audience`]: undefined,
        [`${SE_GENERIC}-geo`]: undefined,
        [`${SE_GENERIC}-has_plus`]: hasPlus ? 'yes' : 'no',
        [`${SE_GENERIC}-weather`]: undefined,
        [`${SE_GENERIC}-loggedin`]: isLoggedIn ? 'yes' : 'no',
        [`${SE_GENERIC}-mobile-os`]: mobileOS,
        [`${SE_GENERIC}-page`]: articleId ? 'article' : 'index',
        [`${SE_GENERIC}-pagegen`]: pageGen || undefined,
        [`${SE_GENERIC}-screenheight`]: window.screen.height.toString(),
        [`${SE_GENERIC}-screenwidth`]: window.screen.width.toString(),
        [`${SE_GENERIC}-sitemode`]: deviceType.replace(PHONE, 'mobile'),
        [`${SE_GENERIC}-story`]: undefined,
        [`${SE_GENERIC}-tags`]: undefined,
        [`${SE_GENERIC}-viewportheight`]: viewportInfo.height.toString(),
        [`${SE_GENERIC}-viewportwidth`]: viewportInfo.width.toString(),
    };
}
