// https://github.schibsted.io/snt/farticles/blob/master/front/core/js/bootstraps/video-player.js

import get from 'lodash.get';
import { getDeviceType, PHONE, TABLET } from '../util';
import appnexusConfig from './snt/appnexus-config';

/**
 * @param {Object} adsConfig
 * @param {String} deviceType phone|tablet|desktop
 * @returns {Number}
 */
function prerollAd(adsConfig, deviceType) {
    if (deviceType === PHONE) {
        return adsConfig.prerollPhone;
    }
    if (deviceType === TABLET) {
        return adsConfig.prerollTablet;
    }
    return adsConfig.prerollDesktop;
}

/**
 * @param {Object} adsConfig
 * @param {String} deviceType phone|tablet|desktop
 * @returns {Number}
 */
function midrollAd(adsConfig, deviceType) {
    if (deviceType === PHONE) {
        return adsConfig.midrollPhone;
    }
    if (deviceType === TABLET) {
        return adsConfig.midrollTablet;
    }
    return adsConfig.midrollDesktop;
}

/**
 *
 * @param {String} vendorName ap|bt|fvn|sa
 * @returns {String}
 */
function getVideoSubCategory(vendorName) {
    const vendor = vendorName.toLowerCase();
    if (vendor === '100sport' || vendor === 'riks') {
        return vendor;
    }
    return '';
}

/**
 * @param {String}
 * @returns {Object}
 */
function vendorConfigSnt(vendor) {
    const adSubCategory = getVideoSubCategory(vendor);
    const deviceType = getDeviceType();
    const adSupplyType = `web_${deviceType}`;
    const adsConfig = appnexusConfig.get(vendor);
    const prerollId = prerollAd(adsConfig, deviceType);
    const midrollId = midrollAd(adsConfig, deviceType);
    return {
        adn(stream) {
            const adCategory = get(stream, 'attributes.category.attributes.id');
            return Promise.resolve({
                member: 9700,
                adSlots: {
                    schedule: {
                        preroll: {
                            id: prerollId,
                            'no-sno-adformat': 'preroll',
                            'kw_no-sno-adformat': 'preroll',
                        },
                        midroll: {
                            id: midrollId,
                            'no-sno-adformat': 'midroll',
                            'kw_no-sno-adformat': 'midroll',
                        },
                    },
                    params: {
                        'aa-sch-country_code': 'no',
                        'kw_aa-sch-country_code': 'no',
                        'no-sno-publishergroup': 'schibsted',
                        'kw_no-sno-publishergroup': 'schibsted',
                        'aa-sch-supply_type': adSupplyType,
                        'kw_aa-sch-supply_type': adSupplyType,
                        'no-sno-news-tv_category': adCategory,
                        'kw_no-sno-news-tv_category': adCategory,
                        'no-sno-news-tv_subcategory': adSubCategory,
                        'kw_no-sno-news-tv_subcategory': adSubCategory,
                        'aa-sch-publisher': adsConfig.id,
                        'kw_aa-sch-publisher': adsConfig.id,
                    },
                },
            });
        },
        vendor,
    };
}

export default vendorConfigSnt;
