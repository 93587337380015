// https://github.schibsted.io/ab/hyperion/blob/master/src/client/video/player-config.js
import get from 'lodash.get';
import mapKeys from 'lodash.mapkeys';
import mapValues from 'lodash.mapvalues';
import { getDeviceType, getUrlParameterKeywords } from '../util';
import { generateInvCode } from './ab/ast-utils';
import getANParams from './ab/ast-params';

/**
 *
 * @param {String} invCode
 * @returns {String}1
 */
function changeInvCodeToTest(invCode) {
    // eslint-disable-next-line prefer-regex-literals
    const sectionRegex = new RegExp('-([a-z]*|[A-Z]*)_');

    return invCode.replace(sectionRegex, '-test_');
}

/**
 *
 * @param {String} invCodeTemplate
 * @param {Boolean} useTestPlacements
 * @returns {Function}
 */
function invCodeCreator(invCodeTemplate, useTestPlacements) {
    let baseInvCode = generateInvCode({ invCode: invCodeTemplate });

    if (useTestPlacements) {
        baseInvCode = changeInvCodeToTest(baseInvCode);
    }

    return (position) => baseInvCode.replace('{position}', position);
}

/**
 * @param {Object} options
 * @param {Boolean} options.autoplay
 * @param {Boolean} options.mute
 * @returns {String} manual|auto|silent
 */
function getStartMode({ autoplay, mute }) {
    if (autoplay) {
        return 'auto';
    }
    if (mute) {
        return 'silent';
    }
    return 'manual';
}

/**
 *
 * @param {String} startMode auto|silent|manual
 * @returns {Number}
 */
function getVplaybackmethod(startMode) {
    if (startMode === 'auto') {
        return 1; // 1 - Auto-play, sound-on
    }

    if (startMode === 'silent') {
        return 2; // 2 - Auto-play, sound-off
    }

    return 3; // 3 - Click-to-play - default
}

/**
 *
 * @param {Object} options
 * @param {Boolean} options.autoplay
 * @param {Boolean} options.mute
 * @param {Boolean} options.playNext
 * @param {Number} options.nextAssetId
 * @param {String} [options.locationSearch='']
 * @returns  {Object}
 */
export default function vendorConfigAb({ autoplay, mute, playNext: autoplayNext, nextAssetId, locationSearch = '' }) {
    const queryParams = new URLSearchParams(locationSearch);
    return {
        vendor: 'ab',
        locale: 'se',
        showSponsorLabel: true,
        adn({ attributes: asset }) {
            const adnConfig = {
                member: 9943,
                options: {
                    autoplayMuted: true, // Allow autostarting ads to run, even though user has muted the player
                    vpaidcontrols: true,
                },
                adSlots: {},
            };
            const [category] = get(asset, 'category.attributes.shortPath').split('-');
            const deviceType = getDeviceType();
            const appnexusVideoAdParams = {
                invCode: `se-aftonbladet-tv-{deviceType}-${category}_{position}`,
            };

            const createInvCode = invCodeCreator(appnexusVideoAdParams.invCode, queryParams.has('adtest'));
            const startMode = getStartMode({
                autoplay,
                mute,
            });

            const clientProperties = {
                appnexusAdParams: {
                    sections: ['webbtv', category],
                    pageGen: undefined,
                    tags: undefined,
                    story: undefined,
                },
            };

            const videoAdSchedule = {};

            if (startMode !== 'silent' || get(asset, 'section.metadata.afp')) {
                videoAdSchedule.preroll = { inv_code: createInvCode('preroll') };
            }

            // MIDROLL logic
            videoAdSchedule.midroll = { inv_code: createInvCode('midroll') };

            // POSTROLL logic
            if (!autoplayNext || !nextAssetId) {
                videoAdSchedule.postroll = { inv_code: createInvCode('postroll') };
            }

            adnConfig.adSlots.schedule = videoAdSchedule;

            const urlParameterKeywords = locationSearch.length && mapValues(getUrlParameterKeywords(queryParams, ','), (value) => value || []);

            const keywords = {
                // Sensible default params
                ...getANParams(deviceType, {
                    articleId: undefined,
                    user: { undefined },
                    clientProperties,
                    glimr: undefined,
                }),
                // override with data from appnexusVideoAdParams, it contain more detailed section data than ANParams
                'se-aftonbladet-section': clientProperties.appnexusAdParams.sections.join(','),
                // override with test values from query string
                ...(urlParameterKeywords || {}),
            };

            adnConfig.adSlots.params = mapKeys(keywords, (_, key) => `kw_${key}`);
            adnConfig.adSlots.params.vskippable = '0';
            adnConfig.adSlots.params.vplaybackmethod = getVplaybackmethod(startMode);

            return Promise.resolve(adnConfig);
        },
    };
}
