import get from 'lodash.get';
import mapKeys from 'lodash.mapkeys';
import mapValues from 'lodash.mapvalues';
import _ from 'lodash-fp';

import getAdKeywords from './get-ad-keywords';
import { getUrlParameterKeywords } from '../../util';

/**
 * @param {Object} params
 * @param {String} params.deviceType desktop|tablet|phone
 * @param {String} params.locationSearch
 * @param {Array} [params.streamCategories=[]]
 * @returns {Object}
 */
function getVideoKeywords({ deviceType, streamCategories = [], locationSearch }) {
    const adKeywords = getAdKeywords(deviceType);
    const isKwString = (str) => /^kw_/i.test(str);
    const addKwPrefix = (keywords) => mapKeys(keywords, (val, key) => (isKwString(key) ? key : `kw_${key}`));
    const stripKwPrefix = (keywords) => mapKeys(keywords, (val, key) => (isKwString(key) ? key.substring(3) : key));
    const urlParameterKeywords = locationSearch.length ? getUrlParameterKeywords(new URLSearchParams(locationSearch)) : {};

    const videoAdKeywords = {
        ...adKeywords,
        'se-generic-articleid': get(window, 'SvD.dtmData.article.originId'),
        'se-generic-story': get(window, 'SvD.dtmData.article.story.id'),
        'se-generic-tags': _.flow(
            (item) => get(item, 'dtmData.article.tags', []),
            (tags) => tags.map(({ id }) => id),
            (tags) => tags.join(','),
        )(window.SvD),
    };

    const stringifyArrayValues = (obj) => mapValues(obj, (value) => (Array.isArray(value) ? value.join(',') : value));

    return stringifyArrayValues({
        'kw-prefixed': addKwPrefix({
            ...{
                ...videoAdKeywords,
                'se-svd-section': [...get(videoAdKeywords, 'se-svd-section', []), ...streamCategories].filter(Boolean),
            },
            ...urlParameterKeywords,
        }),
        'kw-stripped': stripKwPrefix({
            ...{
                ...videoAdKeywords,
                'se-svd-section': [...get(videoAdKeywords, 'se-svd-section', []), ...streamCategories].filter(Boolean),
            },
            ...urlParameterKeywords,
        }),
        default: {
            ...{
                ...videoAdKeywords,
                'se-svd-section': [...get(videoAdKeywords, 'se-svd-section', []), ...streamCategories].filter(Boolean),
            },
            ...urlParameterKeywords,
        },
    });
}

export default getVideoKeywords;
