/**
 * @param {String} page
 * @returns {String}
 */
function mapGenericPageType(page) {
    if (page) {
        if (page.includes('article') || page.includes('context')) {
            return 'article';
        }
        if (page.includes('framework')) {
            return 'detail';
        }
    }
    return 'index';
}

export default mapGenericPageType;
