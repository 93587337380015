export const mediaQueries = {
    tablet: '(min-width: 768px)',
    desktop: '(min-width: 1010px)',
};

export const DESKTOP = 'desktop';
export const TABLET = 'tablet';
export const PHONE = 'phone';

/**
 *
 * Detect device type based on viewport width
 * @returns {String} desktop|tablet|phone
 */
export function getDeviceType() {
    if (window.matchMedia(mediaQueries.desktop).matches) {
        return DESKTOP;
    }
    return window.matchMedia(mediaQueries.tablet).matches ? TABLET : PHONE;
}

/**
 * @param {URLSearchParams} queryParams
 * @param {String} [separator='']
 * @returns {Object}
 */
export function getUrlParameterKeywords(queryParams, separator = '') {
    const keyPrefix = 'kv_';

    const paramsMap = new Map(queryParams.entries());
    const keyWords = new Map();

    paramsMap.forEach((v, k) => {
        if (k.startsWith(keyPrefix)) {
            keyWords.set(k.slice(keyPrefix.length), separator ? v.split(separator) : v);
        }
    });

    return keyWords.size > 0 ? Object.fromEntries(keyWords) : undefined;
}

/**
 *
 * @returns {String}
 */
export function getCssScriptURL() {
    const { src } = document.currentScript || document.querySelector('script[src*="js/embed."]');
    return src.replace(/js\/embed(.+)?\.js/, 'css/embed.css');
}

/**
 * Converts seconds to player time property
 *
 * @param {Number} secs Seconds to convert
 * @return {String}
 */
export function secondsToTime(secs) {
    const pad = (num) => `0${num}`.slice(-2);
    const minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);
    return `${pad(hours)}h${pad(minutes % 60)}m${pad(secs % 60)}s`;
}
