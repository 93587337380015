import { getCssScriptURL } from './util';

export const assetAttribute = 'data-svp-asset';
export const MIN_PLAYER_HEIGHT = 44;

export const defaultRecommended = {
    grid: true,
    autoplay: true,
};

export const nouiSkinConfig = {
    skin: {
        url: getCssScriptURL(),
        name: 'jw-skin-nocontrolbar',
    },
};

export const autoplayViewableConfig = {
    autoplay: 'viewable',
    autopause: 'viewable',
    mute: true,
};
