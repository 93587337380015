import { DEVICE_TYPE_CODE_NARROW, DEVICE_TYPE_CODE_WIDE } from './ad-constants';

export const MIN_SMALL_DEVICE_WIDTH = 590;

function getDeviceType() {
    return window.innerWidth < MIN_SMALL_DEVICE_WIDTH ? DEVICE_TYPE_CODE_NARROW : DEVICE_TYPE_CODE_WIDE;
}

// eslint-disable-next-line import/prefer-default-export
export function generateInvCode(variantToLoad = {}) {
    const { invCode } = variantToLoad;
    const DEVICE_TYPE_PLACEHOLDER = '{deviceType}';
    return invCode ? invCode.replace(DEVICE_TYPE_PLACEHOLDER, getDeviceType()) : undefined;
}
