import _ from 'lodash-fp';

/**
 * @param {String} name
 * @returns {String}
 */
function get(name) {
    const cookies = document.cookie.split(';');
    const cookieNameFilter = _.filter(({ key }) => key.trim() === name);
    const cookieKeyValueMapper = _.map((cookie) => {
        const [key, value] = cookie.split('=');
        return { key, value };
    });

    const matchingCookie = _.flow(cookieKeyValueMapper, cookieNameFilter, _.head)(cookies);
    if (matchingCookie) {
        return matchingCookie.value.trim();
    }

    return false;
}

export default {
    get,
};
