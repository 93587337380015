import customer from './customer';

const inMobileApp = () => navigator.userAgent.includes('SVD-APP');
const mobileAppVersion = () => inMobileApp() && navigator.userAgent.substr(navigator.userAgent.indexOf('SVD-APP v') + 9);
const getCookie = () =>
    decodeURIComponent(
        window.document.cookie.replace(new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent('_pulse2data').replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1'),
    ) || '';

/**
 * @returns {Object}
 */
function getDynamicContext() {
    return {
        loggedIn: customer.isLoggedIn() ? 'yes' : 'no',
        // activeSubscription: customer.hasActiveSubscription(),
        // activateTestPlacements: window.location.href.includes('adtest'),
        adId: getCookie().split(',')[9],
        app: inMobileApp() ? 'hybrid' : 'notinapp',
        appVersion: mobileAppVersion() || '',
        // glimr: glimr.getValues(),
    };
}

export default getDynamicContext;
