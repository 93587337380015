import getDynamicContext from './get-dynamic-context';
import mapGenericPageType from './map-generic-page-type';

/**
 * @param {String} deviceType desktop|tablet|phone
 * @returns {String}
 */
function getSupplyType(deviceType) {
    return `web_${deviceType}`;
}

/**
 * @returns {Object}
 */
function getStaticContext() {
    return {
        pageType: '', // article|context|framework
        stories: [],
        tags: [],
    };
}

/**
 * @param {String} deviceType desktop|tablet|phone
 * @returns {Object}
 */
function getAdKeywords(deviceType) {
    const dynamicContext = getDynamicContext();
    const staticContext = getStaticContext();
    const { screen } = window;
    return {
        'aa-sch-country_code': 'se',
        'aa-sch-publisher': 'svd',
        'aa-sch-supply_type': getSupplyType(deviceType),
        'se-generic-appmode': dynamicContext.app,
        'se-generic-appversion': dynamicContext.appVersion,
        // 'se-generic-articleid': staticContext.articleIdentifier,
        // 'se-generic-geo': dynamicContext.glimr,
        // 'se-generic-geofence': dynamicContext.glimr,
        // 'se-generic-weather': dynamicContext.glimr,
        // 'se-generic-loggedin': dynamicContext.loggedIn,
        'se-generic-page': mapGenericPageType(staticContext.pageType),
        'se-generic-pagegen': 'eclipse',
        'se-generic-sitemode': deviceType,
        'se-generic-screenwidth': screen.width || 0,
        'se-generic-screenheight': screen.height || 0,
        'se-generic-viewportwidth': Math.max(window.document.documentElement.clientWidth, window.innerWidth || 0),
        'se-generic-viewportheight': Math.max(window.document.documentElement.clientHeight, window.innerHeight || 0),
        'se-generic-story': staticContext.stories,
        'se-generic-tags': staticContext.tags,
        'se-generic-topic': staticContext.tags.concat(staticContext.stories),
        'se-generic-pulseid': dynamicContext.adId,
        // 'se-svd-section': getSectionValues(staticContext),
    };
}

export default getAdKeywords;
