// Module from https://github.schibsted.io/vg/shared-components/tree/master/packages/PlayerConfig
import getPlayerConfig from '@vg/player-config';
import sntConfig from './vendor-config/snt';
import svdConfig from './vendor-config/svd';
import abConfig from './vendor-config/ab';

const vendorConfig = new Map([
    [
        'vgtv',
        ({ pluginTracking, enablePluginMuted: muted, recommended }) =>
            getPlayerConfig({ site: 'vgtv', plugins: { podcast: false, tracking: pluginTracking, muted } }, { recommended }),
    ],
    ['svd', ({ locationSearch }) => svdConfig({ locationSearch })],
    ['ap', () => sntConfig('ap')],
    ['bt', () => sntConfig('bt')],
    ['fvn', () => sntConfig('fvn')],
    ['sa', () => sntConfig('sa')],
    ['riks', () => sntConfig('ap')], // use ap config
    ['100sport', () => sntConfig('bt')], // use bt config
    [
        'ab',
        ({ autoplay, mute, playNext, nextAssetId, locationSearch }) =>
            abConfig({
                autoplay,
                mute,
                playNext,
                nextAssetId,
                locationSearch,
            }),
    ],
]);

/**
 *
 * @param {String} provider
 * @param {Object} options
 * @param {Boolean} [options.autoplay[
 * @param {Boolean} [options.mute]
 * @param {Boolean} [options.playNext]
 * @param {Number} [options.nextAssetId]
 * @param {Boolean} [options.pluginTracking=true]
 * @param {Boolean} [options.recommended]
 * @param {Boolean} [options.enablePluginMuted=true]
 * @returns {Object}
 */
function getVendorConfig(provider, { autoplay, mute, playNext, nextAssetId, recommended, enablePluginMuted, pluginTracking = true }) {
    return vendorConfig.has(provider)
        ? vendorConfig.get(provider)({
              autoplay,
              mute,
              playNext,
              nextAssetId,
              pluginTracking,
              recommended,
              enablePluginMuted,
              locationSearch: document.location.search,
          })
        : {
              vendor: provider,
          };
}

export default getVendorConfig;
