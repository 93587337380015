// https://github.schibsted.io/snt/farticles/blob/master/front/aftenposten/js/bootstraps/_appnexus-ads-videoconfig.js
// https://github.schibsted.io/snt/farticles/blob/master/front/bt/js/bootstraps/appnexus-ads-videoconfig.js
// https://github.schibsted.io/snt/farticles/blob/master/front/fvn/js/bootstraps/appnexus-ads-videoconfig.js
// https://github.schibsted.io/snt/farticles/blob/master/front/sa/js/bootstraps/_appnexus-ads-videoconfig.js

const appnexusConfig = new Map([
    [
        'ap',
        {
            id: 'no-aftenposten',
            prerollPhone: 14026394,
            prerollTablet: 14026374,
            prerollDesktop: 14026353,
            midrollPhone: 14026394,
            midrollTablet: 14026374,
            midrollDesktop: 14026353,
        },
    ],
    [
        'bt',
        {
            id: 'no-bergenstidende',
            prerollPhone: 14092428,
            prerollTablet: 14092409,
            prerollDesktop: 14092408,
            midrollPhone: 14092428,
            midrollTablet: 14092409,
            midrollDesktop: 14092408,
        },
    ],
    [
        'fvn',
        {
            id: 'no-faedrelandsvennen',
            prerollPhone: 14092863,
            prerollTablet: 14092884,
            prerollDesktop: 14092844,
            midrollPhone: 14092863,
            midrollTablet: 14092884,
            midrollDesktop: 14092844,
        },
    ],
    [
        'sa',
        {
            id: 'no-stavangeraftenblad',
            prerollPhone: 14096106,
            prerollTablet: 14096127,
            prerollDesktop: 14096085,
            midrollPhone: 14096106,
            midrollTablet: 14096127,
            midrollDesktop: 14096085,
        },
    ],
]);

export default appnexusConfig;
