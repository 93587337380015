import forEach from 'lodash.foreach';
import cookie from './cookie-handler';

/**
 * @returns {Object}
 */
function getCustomerData() {
    const result = {};
    const rawCookie = cookie.get('svd-wp');

    if (rawCookie) {
        const cookieData = decodeURIComponent(rawCookie).split('|');

        forEach(cookieData, (data) => {
            const [key, value] = data.split(':');
            result[key] = value;
        });
    }
    return result;
}

/**
 * @returns {Boolean}
 */
function isLoggedIn() {
    const customer = getCustomerData();
    return 'date' in customer && 'spid' in customer;
}

export default {
    isLoggedIn,
};
